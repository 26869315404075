export function scrollTo(selector) {
    if (!selector && window.scrollTo === undefined) {
        return;
    }

    if (!selector && window.scrollTo !== undefined) {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return;
    }

    setTimeout(function () {
        const element = document.querySelector(selector) || null;

        if (element) {
            element.parentNode.scrollIntoView();
        }
    }, 500);
}
