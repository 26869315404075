<script setup>
import { computed } from 'vue';
import { scrollTo } from '@/helpers/scroll';
import BaseInput from '@/components/layouts/BaseInput.vue';
import BaseSelect from '@/components/layouts/BaseSelect.vue';
import AutocompleteAddressForm from './AutocompleteAddressForm.vue';
import InvalidFeedback from '@/components/layouts/InvalidFeedback.vue';
import states from '@/utils/states.json';
import { useForm } from '@/composables/form.js';
import notify from '@/helpers/notify.js';

const props = defineProps({
    sharedReportInviteCode: { type: String, required: true },
});

const form = useForm({
    account_firstname: '',
    account_lastname: '',
    account_email: '',
    account_email_confirmation: '',
    password: '',
    password_confirmation: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_zip: '',
    company_name: '',
    company_type: '',
    company_state: '',
    supporting_document: null,
});

async function submit() {
    let response;

    try {
        response = await form.post(`/shared-report/register/${props.sharedReportInviteCode}`);
    } catch (error) {
        console.error(error);
        notify.error('Failed to register account');

        scrollTo();

        return;
    }

    setTimeout(() => window.location.replace(response.data.redirect), 1000);
};

const billingAddressErrors = computed(() => {
    return {
        street: form.errors.has('billing_address') ? form.errors.get('billing_address') : '',
        city: form.errors.has('billing_city') ? form.errors.get('billing_city') : '',
        state: form.errors.has('billing_state') ? form.errors.get('billing_state') : '',
        zip: form.errors.has('billing_zip') ? form.errors.get('billing_zip') : '',
        startDate: form.errors.has('billing_address.start_date') ? form.errors.get('billing_address.start_date') : '',
    };
});
</script>

<template>
    <div class="px-8 py-8 overflow-hidden bg-white border border-gray-200 rounded-lg">
        <form @submit.prevent>
            <h1 class="text-2xl font-semibold text-gray-900">
                Create Your Account
            </h1>
            <section class="mt-2">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Account Information
                </h3>
                <div class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                        <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>

                        <BaseInput
                            class="mt-1"
                            id="first_name"
                            v-model="form.fields.account_firstname"
                            @input="form.errors.forget('account_firstname')"
                            placeholder="Enter your first name"
                            :error="form.errors.has('account_firstname')"
                        />

                        <InvalidFeedback :error="form.errors.has('account_firstname')">
                            {{ form.errors.get('account_firstname') }}
                        </InvalidFeedback>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="last_name" class="block text-sm font-medium text-gray-700">Last Name</label>

                        <BaseInput
                            class="mt-1"
                            id="last_name"
                            v-model="form.fields.account_lastname"
                            @input="form.errors.forget('account_lastname')"
                            placeholder="Enter your last name"
                            :error="form.errors.has('account_lastname')"
                        />

                        <InvalidFeedback :error="form.errors.has('account_lastname')">
                            {{ form.errors.get('account_lastname') }}
                        </InvalidFeedback>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>

                        <BaseInput
                            class="mt-1"
                            id="email"
                            v-model="form.fields.account_email"
                            @input="form.errors.forget('account_email')"
                            placeholder="Enter your email"
                            :error="form.errors.has('account_email')"
                        />

                        <InvalidFeedback :error="form.errors.has('account_email')">
                            {{ form.errors.get('account_email') }}
                        </InvalidFeedback>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="email_confirmation" class="block text-sm font-medium text-gray-700">Confirm Email</label>

                        <BaseInput
                            class="mt-1"
                            id="email_confirmation"
                            v-model="form.fields.account_email_confirmation"
                            @input="form.errors.forget('account_email_confirmation')"
                            placeholder="Confirm your email"
                            :error="form.errors.has('account_email_confirmation')"
                        />

                        <InvalidFeedback :error="form.errors.has('account_email_confirmation')">
                            {{ form.errors.get('account_email_confirmation') }}
                        </InvalidFeedback>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>

                        <BaseInput
                            class="mt-1"
                            id="password"
                            v-model="form.fields.password"
                            @input="form.errors.forget('password')"
                            placeholder="Enter your password"
                            :error="form.errors.has('password')"
                            type="password"
                        />

                        <InvalidFeedback :error="form.errors.has('password')">
                            {{ form.errors.get('password') }}
                        </InvalidFeedback>
                    </div>
                    <div class="sm:col-span-3">
                        <label for="password_confirmation" class="block text-sm font-medium text-gray-700">Confirm Password</label>

                        <BaseInput
                            class="mt-1"
                            id="password_confirmation"
                            v-model="form.fields.password_confirmation"
                            @input="form.errors.forget('password_confirmation')"
                            placeholder="Confirm your password"
                            :error="form.errors.has('password_confirmation')"
                            type="password"
                        />

                        <InvalidFeedback :error="form.errors.has('password_confirmation')">
                            {{ form.errors.get('password_confirmation') }}
                        </InvalidFeedback>
                    </div>
                </div>
            </section>

            <hr class="mt-8 border-t border-gray-200">

            <section class="mt-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Company Information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                    <strong>Why do you need this company information?</strong>
                    The FCRA requires we collect this company information to credential your account and ensure you have permissable purpose to order a ClearHire Employment verification.
                </p>
                <div class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                        <label for="company_name" class="block text-sm font-medium text-gray-700">Company Name</label>

                        <BaseInput
                            class="mt-1"
                            id="company_name"
                            v-model="form.fields.company_name"
                            @input="form.errors.forget('company_name')"
                            placeholder="Company Name"
                            :error="form.errors.has('company_name')"
                        />

                        <InvalidFeedback :error="form.errors.has('company_name')">
                            {{ form.errors.get('company_name') }}
                        </InvalidFeedback>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="company_type" class="block text-sm font-medium text-gray-700">Company Entity Type</label>

                        <BaseSelect id="company_type" class="mt-1" v-model="form.fields.company_type" :error="form.errors.has('company_type')" @input="form.errors.forget('company_type')">
                            <option value="" disabled>
                                Select Company Entity Structure
                            </option>
                            <option value="Corp">
                                Corporation (C or S Corp)
                            </option>
                            <option value="LLC">
                                Limited Liability Company (LLC)
                            </option>
                            <option value="Partnership">
                                Partnership (LLP)
                            </option>
                            <option value="Sole">
                                Sole Proprietorship
                            </option>
                            <option value="Non-Profit">
                                Non-Profit
                            </option>
                        </BaseSelect>

                        <InvalidFeedback :error="form.errors.has('company_type')">
                            {{ form.errors.get('company_type') }}
                        </InvalidFeedback>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="company_state" class="block text-sm font-medium text-gray-700">Company Registered State</label>

                        <BaseSelect id="company_state" class="mt-1" v-model="form.fields.company_state" :error="form.errors.has('company_state')" @input="form.errors.forget('company_state')">
                            <option value="">
                                Select State
                            </option>
                            <option v-for="state in states" :key="state.abbreviation" :value="state.abbreviation" :disabled="state.abbreviation === 'RI'">
                                {{ state.name }}
                            </option>
                        </BaseSelect>

                        <InvalidFeedback :error="form.errors.has('company_state')">
                            {{ form.errors.get('company_state') }}
                        </InvalidFeedback>
                        <InvalidFeedback id="report-sku-validation" :error="form.errors.has('report_sku')">
                            {{ form.errors.get('report_sku') }}
                        </InvalidFeedback>
                    </div>
                </div>
            </section>

            <AutocompleteAddressForm
                :street.sync="form.fields.billing_address"
                :city.sync="form.fields.billing_city"
                :state.sync="form.fields.billing_state"
                :zip.sync="form.fields.billing_zip"
                :street-error-message.sync="billingAddressErrors.street"
                :city-error-message.sync="billingAddressErrors.city"
                :state-error-message.sync="billingAddressErrors.state"
                :zip-error-message.sync="billingAddressErrors.zip"
            />

            <div class="flex flex-col mt-8 sm:item-center sm:flex-row">
                <button
                    @click="submit"
                    :disabled="form.busy"
                    class="flex items-center justify-center px-6 py-3 font-medium text-center text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 disabled:opacity-50"
                    :class="{ 'cursor-not-allowed': form.busy }"
                >
                    <svg v-if="form.busy" class="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>

                    <template v-if="!form.busy">
                        Create Account
                        <!-- Heroicon name: solid/arrow-narrow-right -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-3" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </template>

                    <span v-else>Creating your account...</span>
                </button>
            </div>
        </form>
    </div>
</template>
